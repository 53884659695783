#lmds-header {
  position: sticky;
  top: 0;
  z-index: 999;
  
}

.header-logo {
  display: inline-block;
}

.navbar {
  width: 40%;
  list-style: none;
  vertical-align: bottom;
  float: none;
  display: inline-block; 
  margin-top: 20px;
}