.spinner-container {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
}

.spinner {
    margin: -75px 0 0 -75px;
    border: 16px solid #99E5EA;
    border-top: 16px solid #FFD000;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}